<template>
	<div>
		<div class="container">
			<TokenSend :address="address" />
			<TransferList :address="address" />
		</div>
	</div>
</template>

<script>
import TokenSend from '@/components/TokenSend.vue'
import TransferList from '@/components/TransferList.vue'

export default {
	name: 'Index',
	components: {
		TokenSend,
		TransferList
	},
	computed: {
		address() {
			return this.$store.getters['common/wallet/address']
		}
	}
}
</script>
