<template>
	<div v-if="depsLoaded">
		<div class="sp-token-send__holder">
			<div class="sp-component sp-token-send">
				<div class="sp-token-send__header sp-component-title">
					<h3>Deposit bars</h3>
				</div>
				<div class="sp-token-send__main sp-box sp-shadow">
					<form class="sp-token-send__main__form">
						<div class="sp-token-send__main__rcpt__header sp-box-header">Bar No.</div>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="barNo" v-model="deposit.barNo" placeholder="Enter Bar No." :disabled="!address" />
							</div>
						</div>

						<div class="sp-token-send__main__rcpt__header sp-box-header">Brand Mark</div>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="brandMark" v-model="deposit.brandMark" placeholder="Enter brand mark..." :disabled="!address" />
							</div>
						</div>

						<div class="sp-token-send__main__rcpt__header sp-box-header">No. of Pieces</div>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="noOfPieces" v-model="deposit.noOfPieces" placeholder="Enter No. of Pieces..." :disabled="!address" />
							</div>
						</div>

						<div class="sp-token-send__main__rcpt__header sp-box-header">Bar weight (in Grams)</div>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="barWeight" v-model="deposit.barWeight" placeholder="Enter bar weight in Grams..." :disabled="!address" />
							</div>
						</div>

						<div class="sp-token-send__main__rcpt__header sp-box-header">Fine weight (in Grams)</div>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="fineWeight" v-model="deposit.fineWeight" placeholder="Enter fine weight in Grams..." :disabled="!address" />
							</div>
						</div>

						<div class="sp-token-send__main__rcpt__header sp-box-header">Certificate URL</div>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="url" v-model="deposit.url" placeholder="Enter certificate url..." :disabled="!address" />
							</div>
						</div>

						<div class="sp-token-send__main__footer" v-if="address">
							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<div class="sp-token-send__main__btns__reset" v-on:click="resetDeposit">Reset</div>
									<SpButton v-on:click="createBar" type="primary" :disabled="!validForm" :busy="inFlight">Deposit</SpButton>
								</div>
							</div>
						</div>
						<div class="sp-token-send__main__footer" v-else>
							<div class="sp-token-send__main__fees__header sp-box-message">Access a wallet to deposit bars</div>
							<div class="sp-token-send__main__fees__content"></div>
							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<SpButton v-on:click="createBar" type="primary" :disabled="!validForm">Deposit</SpButton>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import BigNumber from 'bignumber.js'

const _ = require('lodash');

export default defineComponent({
	name: 'BarCreate',
	props: {
		address: String
	},
	data: function () {
		return {
			deposit: {
				barNo: '',
				brandMark: '',
				noOfPieces: '',
				barWeight: '',
				fineWeight: '',
				url: '',
				denom: 'ucrg',
			},
			fee: {amount: '0', denom: 'ucrg'},
			bankAddress: '',
			inFlight: false
		}
	},
	beforeCreate: function () {
		const vuexModule = ['metdeal.cryptounce.depository.v1']
		for (let i = 1; i <= vuexModule.length; i++) {
			const submod = vuexModule.slice(0, i)
			if (!this.$store.hasModule(submod)) {
				console.log('Module `metdeal.cryptounce.depository` has not been registered!')
				this._depsLoaded = false
				break
			}
		}
	},
	mounted: function () {
		this.bankAddress = this.address ?? ''
	},
	computed: {
		validForm: function () {
			return !isNaN(parseInt(this.deposit.barNo)) &&
					this.deposit.brandMark &&
					!isNaN(parseInt(this.deposit.noOfPieces)) &&
					!isNaN(parseFloat(this.deposit.barWeight)) &&
					!isNaN(parseFloat(this.deposit.fineWeight))
		},
		depsLoaded: function () {
			return this._depsLoaded
		}
	},
	watch: {
		address: function (newAddr, oldAddr) {
			if (this._depsLoaded) {
				if (newAddr != oldAddr) {
					this.bankAddress = newAddr
					if (this.bankAddress != '') {
						this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
							params: { address: this.bankAddress },
							options: { subscribe: this.refresh }
						})
					}
				}
			}
		}
	},
	methods: {
		createBar: async function () {
			if (this._depsLoaded && this.address) {
				if (this.validForm && !this.inFlight) {
					try {
						const precision = BigNumber(10).exponentiatedBy(18)
						const value = {
							creator: this.address,
							denom: 'ucrg',
							number: this.deposit.barNo,
							brand: this.deposit.brandMark,
							pieces: this.deposit.noOfPieces,
							weight: BigNumber(this.deposit.barWeight).multipliedBy(precision).toFixed(),
							fineWeight: BigNumber(this.deposit.fineWeight).multipliedBy(precision).toFixed(),
							uri: this.deposit.url
						}
						this.inFlight = true

						const txResult = await this.$store.dispatch('metdeal.cryptounce.depository.v1/sendMsgCreateBar', {
							value: value,
							fee: [this.fee],
							memo: ''
						})

						if (txResult && !txResult.code) {
							this.resetDeposit()
							await this.$store.dispatch('metdeal.cryptounce.depository.v1/QueryBarAll', { options: {} }, {})
						}
					} catch (e) {
						console.error(e)
					} finally {
						this.inFlight = false
					}
				}
			}
		},
		resetDeposit: function () {
			this.deposit.barNo = ''
			this.deposit.brandMark = ''
			this.deposit.noOfPieces = ''
			this.deposit.barWeight = ''
			this.deposit.fineWeight = ''
			this.deposit.url = ''
			this.deposit.denom = 'ucrg'
			this.fee = {amount: '0', denom: 'ucrg'}

		}

	}
})
</script>
