<template>
	<div>
		<div class="container">
			<Delegate :address="address" />
			<Reward :address="address" />
		</div>
	</div>
</template>

<script>
import Reward from '@/components/Reward.vue'
import Delegate from '@/components/Delegate.vue'

export default {
	name: 'Rewards',
	components: {
		Reward,
		Delegate
	},
	computed: {
		address() {
			return this.$store.getters['common/wallet/address']
		}
	}
}
</script>
