<template>
	<div v-if="depsLoaded">
		<div class="sp-token-send__holder">
			<div class="sp-component sp-token-send">
				<div class="sp-token-send__header sp-component-title">
					<h3>Send tokens</h3>
					<span>|</span>
					<span>Transfer one or multiple tokens</span>
				</div>
				<div class="sp-token-send__main sp-box sp-shadow">
					<form class="sp-token-send__main__form">
						<div class="sp-token-send__main__rcpt__header sp-box-header">SEND TO</div>
						<select name="channel" v-model="transfer.channel" v-if="availableChannels.length > 0">
							<option value="">This chain</option>
							<option v-for="channel in availableChannels" v-bind:key="channel.src.channelId" :value="channel.src.channelId">
								{{ channel.chainIdB }}
							</option>
						</select>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__icon">
								<span class="sp-icon sp-icon-UpArrow" />
							</div>
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="rcpt" v-model="transfer.recipient" placeholder="Recipient address..." :disabled="!address" />
							</div>
							<div class="sp-token-send__main__rcpt__memo__btn" v-on:click="memoOpen = true" v-if="!memoOpen && address">+Add memo</div>
						</div>
						<div class="sp-token-send__main__rcpt__memo__header sp-box-header" v-if="memoOpen">
							MEMO
							<span class="sp-icon sp-icon-Close" v-on:click="memoOpen = false"></span>
						</div>
						<div class="sp-token-send__main__rcpt__memo" v-if="memoOpen">
							<textarea class="sp-token-send__main__rcpt__memo__content sp-textarea" v-model="transfer.memo" />
						</div>
						<div class="sp-token-send__main__amt__header sp-box-header">AMOUNT</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="balances.length > 0 && address">
							<AmountSelect
								v-for="(amount, index) in transfer.amount"
								:index="index"
								:last="transfer.amount.length == 1"
								v-model="transfer.amount[index]"
								:available="balances"
								:selected="selectedDenoms"
								v-bind:key="'amount' + index"
								@update:modelValue="updateFees"
								v-on:self-remove="transfer.amount.splice(index, 1)"
							/>
							<div class="sp-token-send__main__amt__add" v-if="transfer.channel == '' && nextToAdd != null" v-on:click="addToken">+ Add Token</div>
						</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="!address">
							<div class="sp-amount-select sp-amount-select__dummy">
								<div class="sp-form-group">
									<div class="sp-amount-select__denom">
										<div class="sp-amount-select__denom__selected">
											<div class="sp-amount-select__denom__name">
												<div class="sp-denom-marker" style="background: #809cff" />
												<div class="sp-dummy-fill" />
											</div>
										</div>
									</div>
									<input class="sp-input sp-input-large" value="0" name="rcpt" disabled="true" />
								</div>
							</div>
						</div>

						<div class="sp-token-send__main__footer" :class="{ 'sp-token-send__main__footer__open': feesOpen }" v-if="address">
							<div class="sp-token-send__main__fees__header sp-box-header">
								<span style="margin-right: 10px">FEES </span>
								<span v-if="feesOpen" v-on:click="feesOpen = false" class="sp-icon sp-icon-UpCaret"></span>
							</div>
							<div class="sp-token-send__main__fees__content">
								<template v-if="feesOpen">
									<div class="sp-token-send__main__fees__small" style="padding: 10px;">
										<span style="margin-right: 10px">GAS FEE </span>
										<span>
											<strong>{{ networkFee.amount }}</strong>
											{{ networkFee.denom.toUpperCase() }}
										</span>
									</div>
									<div class="sp-token-send__main__fees__small" style="padding: 10px;">
										<span style="margin-right: 10px">TRANSFER FEE </span>
										<span>
											<strong>{{ transferFee.amount }}</strong>
											{{ transferFee.denom.toUpperCase() }}
										</span>
									</div>
								</template>
								<template v-else>
									<div class="sp-token-send__main__fees__small">
										<span>
											<strong>{{ totalFee.amount }}</strong>
											{{ totalFee.denom.toUpperCase() }}
										</span>
										<span v-on:click="feesOpen = true" class="sp-icon sp-icon-DownCaret"></span>
									</div>
								</template>
							</div>
							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<div class="sp-token-send__main__btns__reset" v-on:click="resetTransaction">Reset</div>
									<SpButton v-on:click="sendTransaction" type="primary" :disabled="!validForm" :busy="inFlight">Send transaction</SpButton>
								</div>
							</div>
						</div>
						<div class="sp-token-send__main__footer" v-else>
							<div class="sp-token-send__main__fees__header sp-box-message">Access a wallet to send transactions</div>
							<div class="sp-token-send__main__fees__content"></div>
							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<SpButton v-on:click="sendTransaction" type="primary" :disabled="!validForm">Send transaction</SpButton>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="sp-component sp-assets__wrapper">
				<SpAssets :balances="balances" />
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue'
import { SpButton, SpAssets } from '@starport/vue'
import { Bech32 } from '@cosmjs/encoding'
import AmountSelect from './AmountSelect.vue'
import Coin from '@/utils/coin.js'
import Constants from '@/common/constants.js'
import BigNumber from 'bignumber.js'

const _ = require('lodash');

export default defineComponent({
	name: 'TokenSend',
	components: {
		AmountSelect,
		SpButton,
		SpAssets
	},
	props: {
		address: String,
		refresh: Boolean
	},
	data: function () {
		return {
			transfer: {
				recipient: '',
				channel: '',
				amount: [],
				memo: ''
			},
			networkFee: {amount: 0, denom: 'CRG'},
			transferFee: {amount: 0, denom: 'CRG'},
			gas: 0,
			feesOpen: false,
			memoOpen: false,
			inFlight: false,
			bankAddress: '',
			staking: {},
			denomTraces: {},
			coins: []
		}
	},
	beforeCreate: function () {
		const vuexModule = ['cosmos.bank.v1beta1']
		for (let i = 1; i <= vuexModule.length; i++) {
			const submod = vuexModule.slice(0, i)
			if (!this.$store.hasModule(submod)) {
				console.log('Module `cosmos.cosmos-sdk.bank` has not been registered!')
				this._depsLoaded = false
				break
			}
		}
	},
	mounted: function () {
		this.bankAddress = this.address ?? ''
		this.staking = this.$store.getters['cosmos.staking.v1beta1/getParams']()
		if (this._depsLoaded) {
			if (this.bankAddress != '') {
				this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
					params: { address: this.address },
					options: { all: true, subscribe: this.refresh }
				})
			}
		}
	},
	watch: {
		balances: function (newBal, oldBal) {
			if (newBal != oldBal && newBal[0]?.denom && oldBal.length == 0) {
				this.transfer.amount = [{ amount: '', denom: newBal[0].denom }]
				this.transfer.networkFee = { amount: '0', denom: newBal[0].denom }
			}
		},
		address: function (newAddr, oldAddr) {
			if (this._depsLoaded) {
				if (newAddr != oldAddr) {
					this.bankAddress = newAddr
					if (this.bankAddress != '') {
						this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
							params: { address: this.bankAddress },
							options: { subscribe: this.refresh }
						})
					}
				}
			}
		}
	},
	computed: {
		validForm: function () {
			if (
				this.transfer.amount.every((x) => !isNaN(this.parseAmount(x.amount)) && x.amount != '' && this.parseAmount(x.amount) != 0) &&
				!isNaN(this.parseAmount(this.networkFee.amount)) &&
				this.validAddress &&
				this.address
			) {
				return true
			} else {
				return false
			}
		},
		balances: function () {
			if (this._depsLoaded) {
				let baseBalances =
					this.$store.getters['cosmos.bank.v1beta1/getAllBalances']({
						params: { address: this.bankAddress }
					})?.balances ?? []

				let displayBalances = baseBalances.map((b) => {
					let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
					let metadata = metadatas.find((m) => m.base == b.denom)
					let coin = new Coin(metadata)
					return coin.toDisplay(b.amount, metadata.base)
				})

				return displayBalances
			} else {
				return []
			}
		},
		nextToAdd: function () {
			const i = this.balances.findIndex((x) => !this.selectedDenoms.includes(x.denom))
			if (i == -1) {
				return null
			} else {
				return this.balances[i]
			}
		},
		selectedDenoms: function () {
			return this.transfer.amount.map((x) => x.denom)
		},
		relayers: function () {
			return this.$store.hasModule(['common', 'relayers']) ? this.$store.getters['common/relayers/getRelayers'] : []
		},
		availableChannels: function () {
			return this.relayers?.filter((x) => x.status == 'connected') ?? []
		},
		depsLoaded: function () {
			return this._depsLoaded
		},
		validAddress: function () {
			let toAddress
			try {
				toAddress = !!Bech32.decode(this.transfer.recipient)
			} catch {
				toAddress = false
			}
			return toAddress
		},
		totalFee: function() {
			let fee = BigNumber(this.networkFee.amount).plus(BigNumber(this.transferFee.amount)).toFixed(6);
			return {amount: fee.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1'), denom: this.networkFee.denom}
		}
	},
	methods: {
		updateFees: _.debounce(async function($value) {
			if (this.transfer.amount.every((x) => !isNaN(this.parseAmount(x.amount)) && x.amount != '' && this.parseAmount(x.amount) != 0) && this.validAddress) {
				let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas

				let amounts = this.transfer.amount.map((a) => {
					let metadata = metadatas.find((m) => m.display == a.denom)
					let coin = new Coin(metadata)
					return coin.toBase(a.amount, metadata.display)
				})

				const value = {
					amount: amounts,
					toAddress: this.transfer.recipient,
					fromAddress: this.bankAddress
				}

				try {
					const simulateResult = await this.$store.dispatch('cosmos.bank.v1beta1/sendMsgSimulate', {
						value,
						fee: [{amount: '0', denom: value.amount[0].denom}],
						memo: this.transfer.memo
					})
					this.gas = simulateResult.calculatedGas

					let metadata = metadatas.find((m) => m.display == $value.denom)
					let denom = metadata.denom_units.find((du) => du.denom == metadata.display)

					let baseAmount = BigNumber(this.gas).multipliedBy(BigNumber(Constants.gasPrice)).multipliedBy(BigNumber(10).exponentiatedBy(denom.exponent))
					baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL)

					this.networkFee = {
						amount: baseAmount.multipliedBy(BigNumber(10).exponentiatedBy(-denom.exponent)).toFixed(6),
						denom: this.balances[0].denom
					}

					let transferFee = simulateResult.log[0].events.find((ev) => ev.type == "transfer-fee")
					transferFee = transferFee.attributes.find((a) => a.key == "amount").value
					const [transferFeeAmount, transferFeeDenom] = transferFee.match(/\D+|\d+/g)
					let coin = new Coin(metadata)
					this.transferFee = coin.toDisplay(transferFeeAmount, transferFeeDenom)
					// console.log(transferFee)
					
					// console.log(simulateResult)
				} catch (e) {
					console.error(e)
				}
			}
		}, 500),
		parseAmount(amount) {
			return amount == '' ? 0 : parseFloat(amount)
		},
		resetTransaction: function () {
			this.transfer.amount = [{ amount: '', denom: this.balances[0].denom }]
			this.transfer.recipient = ''
			this.transfer.memo = ''
			this.transfer.channel = ''
			this.networkFee = { amount: '0', denom: this.balances[0].denom }
			this.transferFee = { amount: '0', denom: this.balances[0].denom }
			this.feesOpen = false
			this.memoOpen = false
		},
		addToken: function () {
			this.transfer.amount.push({
				amount: '',
				denom: this.nextToAdd?.denom ?? ''
			})
		},
		sendTransaction: async function () {
			if (this._depsLoaded && this.address) {
				if (this.validForm && !this.inFlight) {
					let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas

					let amounts = this.transfer.amount.map((a) => {
						let metadata = metadatas.find((m) => m.display == a.denom)
						let coin = new Coin(metadata)
						return coin.toBase(a.amount, metadata.display)
					})

					let metadata = metadatas.find((m) => m.display == this.networkFee.denom)
					let coin = new Coin(metadata)
					let fee = coin.toBase(this.networkFee.amount, metadata.display)

					const value = {
						amount: amounts,
						toAddress: this.transfer.recipient,
						fromAddress: this.bankAddress
					}

					this.inFlight = true

					try {
						const txResult = await this.$store.dispatch('cosmos.bank.v1beta1/sendMsgSend', {
							value,
							fee: [fee],
							memo: this.transfer.memo,
							gas: this.gas.toString()
						})
						if (txResult && !txResult.code) {
							this.resetTransaction()
						}
					} catch (e) {
						console.error(e)
					} finally {
						this.inFlight = false
					}
					await this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
						params: { address: this.address },
						options: { all: true, subscribe: false }
					})
				}
			}
		}
	}
})
</script>
