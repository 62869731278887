<template>
	<div>
		<div class="container">
			<BarCreate :address="address" />
			<BarsList :address="address" />
		</div>
	</div>
</template>

<script>
import BarCreate from '@/components/BarCreate.vue'
import BarsList from '@/components/BarsList.vue'

export default {
	name: 'Bars',
	components: {
		BarCreate,
		BarsList
	},
	computed: {
		address() {
			return this.$store.getters['common/wallet/address']
		}
	}
}
</script>
