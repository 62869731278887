<template>
	<SpSidebar v-on:sidebar-open="sidebarOpen = true" v-on:sidebar-close="sidebarOpen = false">
		<template v-slot:default>
			<SpLinkIcon link="/" text="Wallet" icon="Dashboard" />
			<SpLinkIcon link="/bars" text="Bars" icon="Form" />
			<SpLinkIcon link="/emission" text="Emission" icon="Transactions" />
			<SpLinkIcon link="/delegate" text="Delegation" icon="Transactions" />
			<div class="sp-dash"></div>
			<SpLinkIcon href="https://explorer.demo.technogold.com/" target="_blank" text="Block explorer" icon="Form" />
		</template>
		<template v-slot:footer>
			<SpStatusAPI :showText="sidebarOpen" />
			<SpStatusRPC :showText="sidebarOpen" />
			<SpStatusWS :showText="sidebarOpen" />
			<div class="sp-text">Build: v0.0.1</div>
		</template>
	</SpSidebar>
</template>

<script>
export default {
	name: 'Sidebar',
	data() {
		return {
			sidebarOpen: true
		}
	},
	computed: {
		hasWallet() {
			return this.$store.hasModule(['common', 'wallet'])
		}
	}
}
</script>
