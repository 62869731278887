/* eslint-disable */
import { Writer, Reader } from 'protobufjs/minimal';
export const protobufPackage = 'metdeal.cryptounce.depository.v1';
const baseBar = { number: '', brand: '', pieces: 0, weight: '', fineWeight: '', denom: '', value: '', uri: '', uriHash: '' };
export const Bar = {
    encode(message, writer = Writer.create()) {
        if (message.number !== '') {
            writer.uint32(10).string(message.number);
        }
        if (message.brand !== '') {
            writer.uint32(18).string(message.brand);
        }
        if (message.pieces !== 0) {
            writer.uint32(24).uint32(message.pieces);
        }
        if (message.weight !== '') {
            writer.uint32(34).string(message.weight);
        }
        if (message.fineWeight !== '') {
            writer.uint32(42).string(message.fineWeight);
        }
        if (message.denom !== '') {
            writer.uint32(50).string(message.denom);
        }
        if (message.value !== '') {
            writer.uint32(58).string(message.value);
        }
        if (message.uri !== '') {
            writer.uint32(66).string(message.uri);
        }
        if (message.uriHash !== '') {
            writer.uint32(74).string(message.uriHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseBar };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.number = reader.string();
                    break;
                case 2:
                    message.brand = reader.string();
                    break;
                case 3:
                    message.pieces = reader.uint32();
                    break;
                case 4:
                    message.weight = reader.string();
                    break;
                case 5:
                    message.fineWeight = reader.string();
                    break;
                case 6:
                    message.denom = reader.string();
                    break;
                case 7:
                    message.value = reader.string();
                    break;
                case 8:
                    message.uri = reader.string();
                    break;
                case 9:
                    message.uriHash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseBar };
        if (object.number !== undefined && object.number !== null) {
            message.number = String(object.number);
        }
        else {
            message.number = '';
        }
        if (object.brand !== undefined && object.brand !== null) {
            message.brand = String(object.brand);
        }
        else {
            message.brand = '';
        }
        if (object.pieces !== undefined && object.pieces !== null) {
            message.pieces = Number(object.pieces);
        }
        else {
            message.pieces = 0;
        }
        if (object.weight !== undefined && object.weight !== null) {
            message.weight = String(object.weight);
        }
        else {
            message.weight = '';
        }
        if (object.fineWeight !== undefined && object.fineWeight !== null) {
            message.fineWeight = String(object.fineWeight);
        }
        else {
            message.fineWeight = '';
        }
        if (object.denom !== undefined && object.denom !== null) {
            message.denom = String(object.denom);
        }
        else {
            message.denom = '';
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = String(object.value);
        }
        else {
            message.value = '';
        }
        if (object.uri !== undefined && object.uri !== null) {
            message.uri = String(object.uri);
        }
        else {
            message.uri = '';
        }
        if (object.uriHash !== undefined && object.uriHash !== null) {
            message.uriHash = String(object.uriHash);
        }
        else {
            message.uriHash = '';
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        message.number !== undefined && (obj.number = message.number);
        message.brand !== undefined && (obj.brand = message.brand);
        message.pieces !== undefined && (obj.pieces = message.pieces);
        message.weight !== undefined && (obj.weight = message.weight);
        message.fineWeight !== undefined && (obj.fineWeight = message.fineWeight);
        message.denom !== undefined && (obj.denom = message.denom);
        message.value !== undefined && (obj.value = message.value);
        message.uri !== undefined && (obj.uri = message.uri);
        message.uriHash !== undefined && (obj.uriHash = message.uriHash);
        return obj;
    },
    fromPartial(object) {
        const message = { ...baseBar };
        if (object.number !== undefined && object.number !== null) {
            message.number = object.number;
        }
        else {
            message.number = '';
        }
        if (object.brand !== undefined && object.brand !== null) {
            message.brand = object.brand;
        }
        else {
            message.brand = '';
        }
        if (object.pieces !== undefined && object.pieces !== null) {
            message.pieces = object.pieces;
        }
        else {
            message.pieces = 0;
        }
        if (object.weight !== undefined && object.weight !== null) {
            message.weight = object.weight;
        }
        else {
            message.weight = '';
        }
        if (object.fineWeight !== undefined && object.fineWeight !== null) {
            message.fineWeight = object.fineWeight;
        }
        else {
            message.fineWeight = '';
        }
        if (object.denom !== undefined && object.denom !== null) {
            message.denom = object.denom;
        }
        else {
            message.denom = '';
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = object.value;
        }
        else {
            message.value = '';
        }
        if (object.uri !== undefined && object.uri !== null) {
            message.uri = object.uri;
        }
        else {
            message.uri = '';
        }
        if (object.uriHash !== undefined && object.uriHash !== null) {
            message.uriHash = object.uriHash;
        }
        else {
            message.uriHash = '';
        }
        return message;
    }
};
