<template>
	<div class="sp-component sp-transfer-list" v-if="depsLoaded">
		<div class="sp-transfer-list__header sp-component-title">
			<h3>Deposited bars</h3>
			<span>|</span>
			<span>A list of deposited bars</span>
		</div>
		<table class="sp-transfer-list__table sp-box sp-shadow" v-if="bars.length > 0">
			<thead>
				<tr>
					<th>DEPOSITORY</th>
					<th>BAR NO.</th>
					<th>BRAND MARK</th>
					<th>NO. OF PIECES</th>
					<th>BAR WEIGHT <span>(in Grams)</span></th>
					<th>FINE WEIGHT <span>(in Grams)</span></th>
					<th>CERT URL</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="bar in bars" v-bind:key="bar.number" style="text-align: center">
					<td>{{ bar.denom }}</td>
					<td>{{ bar.number }}</td>
					<td>{{ bar.brand }}</td>
					<td>{{ bar.pieces }}</td>
					<td>{{ bar.weight }}</td>
					<td>{{ bar.fine_weight }}</td>
					<td><a :href="bar.uri" target="_blank">Certificate</a></td>
					<td>
						<SpButton v-on:click="deleteBar(bar.number, bar.brand)" type="primary" :disabled="!address" :busy="inFlight">Withdraw</SpButton>
					</td>
				</tr>
			</tbody>
		</table>
		<table class="sp-transfer-list__table sp-box sp-shadow" v-else>
			<tbody>
				<tr>
					<td class="sp-transfer-list__status" colspan="6">
						<div class="sp-transfer-list__status__wrapper">
							<div class="sp-transfer-list__status__icon sp-transfer-list__status__icon__empty">
								<span class="sp-icon sp-icon-Transactions" />
							</div>
							<div class="sp-transfer-list__status__action">
								<div class="sp-transfer-list__status__action__text">There are no deposited bars yet</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import BigNumber from 'bignumber.js'

export default defineComponent({
	name: 'BarsList',
	props: {
		address: String
	},
	data: function () {
		return {
			inFlight: false
		}
	},
	computed: {
		depsLoaded: function () {
			return this._depsLoaded
		},
		bars: function () {
			let barsRes = this.$store.getters['metdeal.cryptounce.depository.v1/getBars']({ params: {} })
			return (
				barsRes?.Bars?.map(function (b) {
					return {
						denom: b.denom,
						number: b.number,
						brand: b.brand,
						pieces: b.pieces,
						weight: BigNumber(b.weight).toFixed(2),
						fine_weight: BigNumber(b.fine_weight).toFixed(2),
						uri: b.uri
					}
				}) || []
			)
		},
		canDelete: function () {
			return true
		}
	},
	mounted: async function () {
		await this.$store.dispatch('metdeal.cryptounce.depository.v1/QueryBars', { options: {} }, {})
	},
	methods: {
		deleteBar: async function (number, brand) {
			if (confirm('Do you really want to withdraw this bar?')) {
				try {
					const value = {
						creator: this.address,
						number: number,
						brand: brand
					}
					this.inFlight = true

					const txResult = await this.$store.dispatch('metdeal.cryptounce.depository.v1/sendMsgDeleteBar', {
						value: value,
						fee: [{ denom: 'ucrg', amount: '40000' }],
						memo: ''
					})

					if (txResult && !txResult.code) {
						await this.$store.dispatch('metdeal.cryptounce.depository.v1/QueryBars', { options: {} }, {})
					}
				} catch (e) {
					console.error(e)
				} finally {
					this.inFlight = false
				}
			}
		}
	}
})
</script>
