<template>
	<div>
		<div class="container">
			<Mint :address="address" />
			<Burn :address="address" />
		</div>
	</div>
</template>

<script>
import Mint from '@/components/Mint.vue'
import Burn from '@/components/Burn.vue'

export default {
	name: 'Emission',
	components: {
		Mint,
		Burn
	},
	computed: {
		address() {
			return this.$store.getters['common/wallet/address']
		}
	}
}
</script>
