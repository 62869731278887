<template>
	<div v-if="depsLoaded">
		<div class="sp-token-send__holder">
			<div class="sp-component sp-token-send">
				<div class="sp-token-send__header sp-component-title">
					<h3>Mint Tokens</h3>
				</div>
				<div class="sp-token-send__main sp-box sp-shadow">
					<form class="sp-token-send__main__form">
						<div class="sp-token-send__main__amt__header sp-box-header">AMOUNT</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="reserves.length > 0 && address">
							<AmountSelect
								v-for="(amount, index) in mint.amount"
								:index="index"
								:last="mint.amount.length == 1"
								v-model="mint.amount[index]"
								:available="reserves"
								:selected="selectedDenoms"
								v-bind:key="'amount' + index"
								v-on:self-remove="mint.amount.splice(index, 1)"
							/>
						</div>

						<div class="sp-token-send__main__footer" v-if="address">
							<div class="sp-token-send__main__fees__header sp-box-header">FEE:</div>
							<div class="sp-token-send__main__fees__content">
								<div class="sp-token-send__main__fees__small">
									<span><strong> 0.04</strong> CRG </span>
								</div>
							</div>

							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<div class="sp-token-send__main__btns__reset" v-on:click="resetMint">Reset</div>
									<SpButton v-on:click="sendMint" type="primary" :disabled="!validForm" :busy="inFlight">Mint</SpButton>
								</div>
							</div>
						</div>

						<div class="sp-token-send__main__footer" v-else>
							<div class="sp-token-send__main__fees__header sp-box-message">Access a wallet to mint tokens</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import AmountSelect from './AmountSelect.vue'
import BigNumber from 'bignumber.js'
import Coin from '@/utils/coin.js'

export default defineComponent({
	name: 'Mint',
	components: {
		AmountSelect
	},
	props: {
		address: String
	},
	data: function () {
		return {
			mint: {
				amount: []
			},
			inFlight: false
		}
	},
	beforeCreate: function () {
		const vuexModule = ['metdeal.cryptounce.depository.v1']
		for (let i = 1; i <= vuexModule.length; i++) {
			const submod = vuexModule.slice(0, i)
			if (!this.$store.hasModule(submod)) {
				console.log('Module `metdeal.cryptounce.depository` has not been registered!')
				this._depsLoaded = false
				break
			}
		}
	},
	mounted: function () {
		if (this._depsLoaded) {
			this.resetMint()
		}
	},
	watch: {
		reserves: function (newReserves, oldReserves) {
			if (newReserves != oldReserves && newReserves[0]?.denom && oldReserves.length == 0) {
				this.mint.amount = [{ amount: '', denom: newReserves[0].denom }]
			}
		}
	},
	computed: {
		validForm: function () {
			return true
		},
		depsLoaded: function () {
			return this._depsLoaded
		},
		reserves: function () {
			let supRes = this.$store.getters['cosmos.bank.v1beta1/getTotalSupply']({ params: {} })
			let res = this.$store.getters['metdeal.cryptounce.depository.v1/getReserveAll']({ params: {} })
			let rawReserves =
				res?.Reserves?.map(function (r) {
					let supply = supRes.supply.find(function (el) {
						return el.denom == r.denom
					})
					return { denom: r.denom, amount: BigNumber(r.value).minus(BigNumber(supply.amount)).toFixed() }
				}) || []
			let displayReserves = rawReserves.map((r) => {
				let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
				let metadata = metadatas.find((m) => m.base == r.denom)
				let coin = new Coin(metadata)
				return coin.toDisplay(r.amount, metadata.base)
			})
			return displayReserves
		},
		selectedDenoms: function () {
			return this.mint.amount.map((x) => x.denom)
		}
	},
	methods: {
		resetMint: async function () {
			let supRes = await this.$store.dispatch('cosmos.bank.v1beta1/QueryTotalSupply', { options: {} }, {})
			var res = await this.$store.dispatch('metdeal.cryptounce.depository.v1/QueryReserveAll', { options: {} }, {})
			if (res?.Reserves?.length > 0) {
				let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
				let metadata = metadatas.find((m) => m.base == res.Reserves[0].denom)
				this.mint.amount = [{ amount: '', denom: metadata.display }]
			}
			this.inFlight = false
		},
		sendMint: async function () {
			if (this._depsLoaded && this.address) {
				if (this.validForm && !this.inFlight) {
					try {
						let amounts = this.mint.amount.map((a) => {
							let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
							let metadata = metadatas.find((m) => m.display == a.denom)
							let coin = new Coin(metadata)
							return coin.toBase(a.amount, metadata.display)
						})
						const value = {
							creator: this.address,
							amount: { denom: amounts[0].denom, amount: amounts[0].amount }
						}
						this.inFlight = true
						const txResult = await this.$store.dispatch('metdeal.cryptounce.depository.v1/sendMsgMint', {
							value,
							fee: [{ denom: 'ucrg', amount: '40000' }],
							memo: ''
						})
						if (txResult && !txResult.code) {
							this.resetMint()
						}
					} catch (e) {
						console.error(e)
					} finally {
						this.inFlight = false
					}
				}
			}
		}
	}
})
</script>
