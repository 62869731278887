<template>
	<div v-if="depsLoaded">
		<div class="sp-token-send__holder">
			<div class="sp-component sp-token-send">
				<div class="sp-token-send__header sp-component-title">
					<h3>Delegate tokens</h3>
					<span>|</span>
					<span>Delegate one or multiple tokens</span>
				</div>
				<div class="sp-token-send__main sp-box sp-shadow">
					<form class="sp-token-send__main__form">
						<div class="sp-token-send__main__rcpt__header sp-box-header">DELEGATE TO</div>
						<select name="channel" v-model="transfer.channel" v-if="availableChannels.length > 0">
							<option value="">This chain</option>
							<option v-for="channel in availableChannels" v-bind:key="channel.src.channelId" :value="channel.src.channelId">
								{{ channel.chainIdB }}
							</option>
						</select>
						<div class="sp-token-send__main__rcpt__wrapper">
							<div class="sp-token-send__main__rcpt__icon">
								<span class="sp-icon sp-icon-UpArrow" />
							</div>
							<div class="sp-token-send__main__rcpt__input sp-form-group">
								<input class="sp-input" name="rcpt" v-model="transfer.recipient" placeholder="Delegator address..." :disabled="!address" />
							</div>
						</div>
						<div class="sp-token-send__main__amt__header sp-box-header">AMOUNT</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="balances.length > 0 && address">
							<AmountSelect
								v-for="(amount, index) in transfer.amount"
								:index="index"
								:last="transfer.amount.length == 1"
								v-model="transfer.amount[index]"
								:available="balances"
								:selected="selectedDenoms"
								v-bind:key="'amount' + index"
								v-on:self-remove="transfer.amount.splice(index, 1)"
							/>
							<div class="sp-token-send__main__amt__add" v-if="transfer.channel == '' && nextToAdd != null" v-on:click="addToken">+ Add Token</div>
						</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="!address">
							<div class="sp-amount-select sp-amount-select__dummy">
								<div class="sp-form-group">
									<div class="sp-amount-select__denom">
										<div class="sp-amount-select__denom__selected">
											<div class="sp-amount-select__denom__name">
												<div class="sp-denom-marker" style="background: #809cff" />
												<div class="sp-dummy-fill" />
											</div>
										</div>
									</div>
									<input class="sp-input sp-input-large" value="0" name="rcpt" disabled="true" />
								</div>
							</div>
						</div>

						<div class="sp-token-send__main__footer" :class="{ 'sp-token-send__main__footer__open': feesOpen }" v-if="address">
							<div class="sp-token-send__main__fees__header sp-box-header">
								FEES <span class="sp-circle">?</span>
								<span v-if="feesOpen" v-on:click="feesOpen = false" class="sp-icon sp-icon-UpCaret"></span>
							</div>
							<div class="sp-token-send__main__fees__content">
								<template v-if="feesOpen">
									<div class="sp-token-send__main__amt__wrapper" v-if="balances.length > 0">
										<AmountSelect
											v-for="(amount, index) in transfer.fees"
											:index="index"
											:last="transfer.fees.length == 1"
											v-model="transfer.fees[index]"
											:available="balances"
											:selected="selectedFeeDenoms"
											v-bind:key="'fee' + index"
											v-on:self-remove="transfer.fees.splice(index, 1)"
										/>
										<div class="sp-token-send__main__amt__add" v-if="nextFeeToAdd != null" v-on:click="addFeeToken">+ Add Fee Token</div>
										<div class="sp-line"></div>
									</div>
								</template>
								<template v-else>
									<div class="sp-token-send__main__fees__small">
										<span v-for="(fee, index) in transfer.fees" v-bind:key="'fee_small' + index">
											<strong>{{ fee.amount }}</strong>
											{{ fee.denom.toUpperCase() }}
										</span>
										<span v-on:click="feesOpen = true" class="sp-icon sp-icon-DownCaret"></span>
									</div>
								</template>
							</div>
							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__reset__fees" v-on:click="resetFees" v-if="feesOpen">Reset Fees</div>
								<div class="sp-token-send__main__btns__tx">
									<div class="sp-token-send__main__btns__reset" v-on:click="resetTransaction">Reset</div>
									<SpButton v-on:click="sendTransaction" type="primary" :disabled="!validForm" :busy="inFlight">Delegate</SpButton>
								</div>
							</div>
						</div>
						<div class="sp-token-send__main__footer" v-else>
							<div class="sp-token-send__main__fees__header sp-box-message">Access a wallet to delegate tokens</div>
							<div class="sp-token-send__main__fees__content"></div>
							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__reset__fees" v-on:click="resetFees" v-if="feesOpen">Reset Fees</div>
								<div class="sp-token-send__main__btns__tx">
									<SpButton v-on:click="sendTransaction" type="primary" :disabled="!validForm">Delegate</SpButton>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue'
import { SpButton } from '@starport/vue'
import { Bech32 } from '@cosmjs/encoding'
import AmountSelect from './AmountSelect.vue'
import Coin from '@/utils/coin.js'

export default defineComponent({
	name: 'Delegate',
	components: {
		AmountSelect,
		SpButton
	},
	props: {
		address: String,
		refresh: Boolean
	},
	data: function () {
		return {
			transfer: {
				recipient: '',
				channel: '',
				amount: [],
				memo: '',
				fees: []
			},
			feesOpen: false,
			memoOpen: false,
			inFlight: false,
			bankAddress: '',
			staking: {},
			denomTraces: {},
			coins: []
		}
	},
	beforeCreate: function () {
		const vuexModule = ['cosmos.bank.v1beta1']
		for (let i = 1; i <= vuexModule.length; i++) {
			const submod = vuexModule.slice(0, i)
			if (!this.$store.hasModule(submod)) {
				console.log('Module `cosmos.cosmos-sdk.bank` has not been registered!')
				this._depsLoaded = false
				break
			}
		}
	},
	mounted: function () {
		this.bankAddress = this.address ?? ''
		this.staking = this.$store.getters['cosmos.staking.v1beta1/getParams']()
		if (this._depsLoaded) {
			if (this.bankAddress != '') {
				this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
					params: { address: this.address },
					options: { all: true, subscribe: this.refresh }
				})
			}
		}
	},
	watch: {
		balances: function (newBal, oldBal) {
			if (newBal != oldBal && newBal[0]?.denom && oldBal.length == 0) {
				this.transfer.amount = [{ amount: '', denom: newBal[0].denom }]
				this.transfer.fees = [{ amount: '0.04', denom: newBal[0].denom }]
			}
		},
		address: function (newAddr, oldAddr) {
			if (this._depsLoaded) {
				if (newAddr != oldAddr) {
					this.bankAddress = newAddr
					if (this.bankAddress != '') {
						this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
							params: { address: this.bankAddress },
							options: { subscribe: this.refresh }
						})
					}
				}
			}
		}
	},
	computed: {
		validForm: function () {
			if (
				this.transfer.amount.every((x) => !isNaN(this.parseAmount(x.amount)) && x.amount != '' && this.parseAmount(x.amount) != 0) &&
				this.transfer.fees.every((x) => !isNaN(this.parseAmount(x.amount))) &&
				this.validAddress &&
				this.address
			) {
				return true
			} else {
				return false
			}
		},
		balances: function () {
			if (this._depsLoaded) {
				let baseBalances =
					this.$store.getters['cosmos.bank.v1beta1/getAllBalances']({
						params: { address: this.bankAddress }
					})?.balances ?? []

				let displayBalances = baseBalances.map((b) => {
					let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
					let metadata = metadatas.find((m) => m.base == b.denom)
					let coin = new Coin(metadata)
					return coin.toDisplay(b.amount, metadata.base)
				})

				return displayBalances
			} else {
				return []
			}
		},
		nextToAdd: function () {
			const i = this.balances.findIndex((x) => !this.selectedDenoms.includes(x.denom))
			if (i == -1) {
				return null
			} else {
				return this.balances[i]
			}
		},
		nextFeeToAdd: function () {
			const i = this.balances.findIndex((x) => !this.selectedFeeDenoms.includes(x.denom))
			if (i == -1) {
				return null
			} else {
				return this.balances[i]
			}
		},
		selectedDenoms: function () {
			return this.transfer.amount.map((x) => x.denom)
		},
		selectedFeeDenoms: function () {
			return this.transfer.fees.map((x) => x.denom)
		},
		relayers: function () {
			return this.$store.hasModule(['common', 'relayers']) ? this.$store.getters['common/relayers/getRelayers'] : []
		},
		availableChannels: function () {
			return this.relayers?.filter((x) => x.status == 'connected') ?? []
		},
		depsLoaded: function () {
			return this._depsLoaded
		},
		validAddress: function () {
			let toAddress
			try {
				toAddress = !!Bech32.decode(this.transfer.recipient)
			} catch {
				toAddress = false
			}
			return toAddress
		}
	},
	methods: {
		parseAmount(amount) {
			return amount == '' ? 0 : parseFloat(amount)
		},
		resetTransaction: function () {
			this.transfer.amount = [{ amount: '', denom: this.balances[0].denom }]
			this.transfer.recipient = ''
			this.transfer.memo = ''
			this.transfer.channel = ''
			this.transfer.fees = [{ amount: '0.04', denom: this.balances[0].denom }]
			this.feesOpen = false
			this.memoOpen = false
		},
		resetFees: function () {
			this.transfer.fees = [{ amount: '0.04', denom: this.balances[0].denom }]
		},
		addToken: function () {
			this.transfer.amount.push({
				amount: '',
				denom: this.nextToAdd?.denom ?? ''
			})
		},
		addFeeToken: function () {
			this.transfer.fees.push({
				amount: '',
				denom: this.nextFeeToAdd?.denom ?? ''
			})
		},
		sendTransaction: async function () {
			if (this._depsLoaded && this.address) {
				if (this.validForm && !this.inFlight) {
					let amounts = this.transfer.amount.map((a) => {
						let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
						let metadata = metadatas.find((m) => m.display == a.denom)
						let coin = new Coin(metadata)
						return coin.toBase(a.amount, metadata.display)
					})

					let fees = this.transfer.fees.map((f) => {
						if (f.amount == '') {
							f.amount = '0'
						}
						let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
						let metadata = metadatas.find((m) => m.display == f.denom)
						let coin = new Coin(metadata)
						return coin.toBase(f.amount, metadata.display)
					})

					const value = {
						amount: amounts[0],
						validatorAddress: this.transfer.recipient,
						delegatorAddress: this.bankAddress
					}

					this.inFlight = true

					try {
						const txResult = await this.$store.dispatch('cosmos.staking.v1beta1/sendMsgDelegate', {
							value,
							fee: fees,
							memo: this.transfer.memo
						})
						if (txResult && !txResult.code) {
							this.resetTransaction()
						}
					} catch (e) {
						console.error(e)
					} finally {
						this.inFlight = false
					}
					await this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
						params: { address: this.address },
						options: { all: true, subscribe: false }
					})
				}
			}
		}
	}
})
</script>
