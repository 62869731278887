import BigNumber from 'bignumber.js'

export default class Coin {
	constructor(metadata) {
		this.metadata = metadata
	}

	toBase(amount, denomFrom) {
		return this.toDenom(amount, denomFrom, this.metadata.base)
	}

	toDisplay(amount, denomFrom) {
		return this.toDenom(amount, denomFrom, this.metadata.display)
	}

	toDenom(amount, denomFromSymbol, denomToSymbol) {
		let denomFrom = this.metadata.denom_units.find((du) => du.denom == denomFromSymbol)
		let denomTo = this.metadata.denom_units.find((du) => du.denom == denomToSymbol)
		if (denomFrom && denomTo) {
			const precision = BigNumber(10).exponentiatedBy(denomFrom.exponent - denomTo.exponent)
			let a = BigNumber(amount).multipliedBy(precision).toFixed(denomTo.exponent)
			return {
				denom: denomToSymbol,
				amount: a.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
			}
		}
		return {
			denom: denomToSymbol,
			amount: 'NaN'
		}
	}
}
