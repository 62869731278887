<template>
	<div v-if="depsLoaded">
		<div class="sp-token-send__holder">
			<div class="sp-component sp-token-send">
				<div class="sp-token-send__header sp-component-title">
					<h3>Burn Tokens</h3>
				</div>
				<div class="sp-token-send__main sp-box sp-shadow">
					<form class="sp-token-send__main__form">
						<div class="sp-token-send__main__amt__header sp-box-header">AMOUNT</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="balances.length > 0 && address">
							<AmountSelect
								v-for="(amount, index) in burn.amount"
								:index="index"
								:last="burn.amount.length == 1"
								v-model="burn.amount[index]"
								:available="balances"
								:selected="selectedDenoms"
								v-bind:key="'amount' + index"
								v-on:self-remove="burn.amount.splice(index, 1)"
							/>
						</div>

						<div class="sp-token-send__main__footer" v-if="address">
							<div class="sp-token-send__main__fees__header sp-box-header">FEE:</div>
							<div class="sp-token-send__main__fees__content">
								<div class="sp-token-send__main__fees__small">
									<span><strong> 0.04</strong> CRG </span>
								</div>
							</div>

							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<div class="sp-token-send__main__btns__reset" v-on:click="resetBurn">Reset</div>
									<SpButton v-on:click="sendBurn" type="primary" :disabled="!validForm" :busy="inFlight">Burn</SpButton>
								</div>
							</div>
						</div>

						<div class="sp-token-send__main__footer" v-else>
							<div class="sp-token-send__main__fees__header sp-box-message">Access a wallet to burn tokens</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import AmountSelect from './AmountSelect.vue'
import BigNumber from 'bignumber.js'
import Coin from '@/utils/coin.js'

export default defineComponent({
	name: 'Burn',
	components: {
		AmountSelect
	},
	props: {
		address: String
	},
	data: function () {
		return {
			burn: {
				amount: []
			},
			fee: {amount: '40000', denom: 'ucrg'},
			inFlight: false,
			bankAddress: ''
		}
	},
	beforeCreate: function () {
		const vuexModule = ['metdeal.cryptounce.depository.v1']
		for (let i = 1; i <= vuexModule.length; i++) {
			const submod = vuexModule.slice(0, i)
			if (!this.$store.hasModule(submod)) {
				console.log('Module `metdeal.cryptounce.depository` has not been registered!')
				this._depsLoaded = false
				break
			}
		}
	},
	mounted: function () {
		if (this._depsLoaded && this.address) {
			this.resetBurn()
		}
	},
	watch: {
		balances: function (newBal, oldBal) {
			if (newBal != oldBal && newBal[0]?.denom && oldBal.length == 0) {
				this.burn.amount = [{ amount: '', denom: newBal[0].denom }]
			}
		}
	},
	computed: {
		validForm: function () {
			return true
		},
		depsLoaded: function () {
			return this._depsLoaded
		},
		balances: function () {
			if (this._depsLoaded) {
				let rawBalances =
					this.$store.getters['cosmos.bank.v1beta1/getAllBalances']({
						params: { address: this.address }
					})?.balances ?? []
				return rawBalances.map((b) => {
					let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
					let metadata = metadatas.find((m) => m.base == b.denom)
					let coin = new Coin(metadata)
					return coin.toDisplay(b.amount, metadata.base)
				})
			} else {
				return []
			}
		},
		selectedDenoms: function () {
			return this.burn.amount.map((x) => x.denom)
		}
	},
	methods: {
		resetBurn: async function () {
			const supplyRes = await this.$store.dispatch('cosmos.bank.v1beta1/QueryTotalSupply', { options: {} }, {})
			console.log(supplyRes)
			const res = await this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
				params: { address: this.address },
				options: { all: true, subscribe: this.refresh }
			})
			let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
			let metadata = metadatas.find((m) => m.base == res.balances[0].denom)
			this.burn.amount = [{ amount: '', denom: metadata.display }]
			this.fee = {amount: '40000', denom: metadata.base}
			this.inFlight = false
		},
		sendBurn: async function () {
			if (this._depsLoaded && this.address) {
				if (this.validForm && !this.inFlight) {
					try {
						let amounts = this.burn.amount.map((a) => {
							let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
							let metadata = metadatas.find((m) => m.display == a.denom)
							let coin = new Coin(metadata)
							return coin.toBase(a.amount, metadata.display)
						})

						const value = {
							creator: this.address,
							amount: { denom: amounts[0].denom, amount: amounts[0].amount }
						}
						this.inFlight = true
						const txResult = await this.$store.dispatch('metdeal.cryptounce.depository.v1/sendMsgBurn', {
							value,
							fee: [this.fee],
							memo: ''
						})
						if (txResult && !txResult.code) {
							this.resetBurn()
						}
					} catch (e) {
						console.error(e)
					} finally {
						this.inFlight = false
					}
				}
			}
		}
	}
})
</script>
