<template>
	<div class="sp-amount-select">
		<div class="sp-amount-select__overlay" v-if="modalOpen" v-on:click="modalOpen = false"></div>
		<div class="sp-form-group" :class="{ 'sp-amount-select__overlay__open': modalOpen }">
			<div class="sp-amount-select__denom" :class="{ 'sp-focused': focused }">
				<div class="sp-amount-select__denom__selected" v-on:click="toggleModal">
					<div class="sp-amount-select__denom__name">
						<div
							class="sp-amount-select__denom__balance"
							:class="{
								'sp-amount-select__denom__balance__fail': parseAmount(fulldenom.amount) - parseAmount(amount) < 0
							}"
						>
							<strong>Avail.</strong>
							{{ diffAmount }}/{{ fulldenom.amount }}
						</div>
						<div class="sp-denom-marker" :style="'background: #' + fulldenom.color" />
						{{ fulldenom.denom.toUpperCase() }}
					</div>
					<div class="sp-amount-select__denom__controls">
						<div class="sp-amount-select__denom__remove" v-if="modalOpen && !last" v-on:click="selfRemove">Remove</div>
						<span
							:class="{
								'sp-icon sp-icon-DownCaret': !modalOpen,
								'sp-icon sp-icon-UpCaret': modalOpen
							}"
						/>
					</div>
				</div>
				<div class="sp-amount-select__denom__modal" v-if="modalOpen">
					<div class="sp-amount-select__denom__modal__search">
						<div class="sp-icon sp-icon-Search" />
						<input type="text" v-model="searchTerm" placeholder="Search..." class="sp-amount-select__denom__modal__search__input" />
					</div>
					<div class="sp-line"></div>
					<div class="sp-amount-select__denom__modal__header">
						<div class="sp-amount-select__denom__modal__header__token">TOKEN</div>
						<div class="sp-amount-select__denom__modal__header__amount">AMOUNT</div>
					</div>
					<div
						class="sp-amount-select__denom__modal__item"
						:class="{
							'sp-amount-select__denom__modal__item__selected': avail.denom == fulldenom.denom,
							'sp-amount-select__denom__modal__item__disabled': enabledDenoms.findIndex((x) => x == avail) == -1
						}"
						v-on:click="setDenom(avail)"
						v-for="avail in filteredDenoms"
						v-bind:key="'denom_' + avail.denom"
					>
						<div class="sp-amount-select__denom__name">
							<div class="sp-denom-marker" :style="'background: #' + avail.color" />
							{{ avail.denom.toUpperCase() }}
						</div>
						<div class="sp-amount-select__denom__balance">
							{{ avail.amount }}
						</div>
					</div>
				</div>
			</div>
			<input
				class="sp-input sp-input-large"
				:class="{
					'sp-error': fulldenom.amount != '' && parseAmount(fulldenom.amount) - parseAmount(amount) < 0
				}"
				name="rcpt"
				v-model="amount"
				placeholder="0"
				v-on:focus="focused = true"
				v-on:blur="focused = false"
			/>
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue'
import BigNumber from 'bignumber.js'

export default defineComponent({
	name: 'AmountSelect',
	data: function () {
		return {
			amount: '',
			denom: null,
			focused: false,
			modalOpen: false,
			searchTerm: ''
		}
	},
	props: {
		modelValue: Object,
		available: Array,
		index: Number,
		selected: Array,
		last: Boolean
	},
	emits: ['update:modelValue', 'self-remove'],
	mounted: function () {
		this.amount = this.modelValue?.amount + '' ?? ''
		this.denom = this.modelValue?.denom ?? null
	},
	computed: {
		currentVal: function () {
			return { amount: this.amount, denom: this.denom ?? '' }
		},
		fulldenom: function () {
			return (
				this.denoms.find((x) => x.denom == this.denom) ?? {
					amount: '',
					denom: '',
					color: ''
				}
			)
		},
		enabledDenoms: function () {
			return (
				this.available?.filter((x) => this.selected?.findIndex((y) => y == x.denom) == -1 || this.selected?.findIndex((y) => y == x.denom) == this.index) ?? []
			)
		},
		denoms: function () {
			return (
				this.available?.map((x) => {
					const y = { amount: '0', denom: '', color: '' }
					y.amount = x.amount
					y.denom = x.denom
					y.color = this.str2rgba(x.denom.toUpperCase())
					return x
				}) ?? []
			)
		},
		filteredDenoms: function () {
			return this.searchTerm == '' ? this.denoms : this.denoms.filter((x) => x.denom.toUpperCase().indexOf(this.searchTerm.toUpperCase()) !== -1)
		},
		diffAmount: function () {
			return BigNumber(this.fulldenom.amount)
				.minus(BigNumber(isNaN(this.amount) || this.amount == '' ? '0' : this.amount))
				.toFixed(6)
				.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
		}
	},
	methods: {
		str2rgba(r) {
			const o = []
			for (let a, c = 0; c < 256; c++) {
				a = c
				for (let f = 0; f < 8; f++) a = 1 & a ? 3988292384 ^ (a >>> 1) : a >>> 1
				o[c] = a
			}
			let n = -1
			for (let t = 0; t < r.length; t++) n = (n >>> 8) ^ o[255 & (n ^ r.charCodeAt(t))]
			return ((-1 ^ n) >>> 0).toString(16)
		},
		toggleModal: function () {
			this.modalOpen = !this.modalOpen
		},
		selfRemove: function () {
			this.$emit('self-remove')
		},
		setDenom: function (avail) {
			if (this.enabledDenoms.findIndex((x) => x == avail) != -1) {
				this.denom = avail.denom
				this.modalOpen = false
			}
		},
		parseAmount: function (amount) {
			return amount == '' ? 0 : parseFloat(amount)
		}
	},
	watch: {
		modelValue: function (newVal) {
			this.amount = newVal.amount
			this.denom = newVal.denom
		},
		amount: function (newVal, oldVal) {
			if (newVal != oldVal) {
				this.$emit('update:modelValue', this.currentVal)
			}
		},
		denom: function (newVal, oldVal) {
			if (newVal != oldVal) {
				this.$emit('update:modelValue', this.currentVal)
			}
		}
	}
})
</script>
