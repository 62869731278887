// THIS FILE IS GENERATED AUTOMATICALLY. DO NOT MODIFY.
import { SigningStargateClient } from "@cosmjs/stargate";
import { Registry } from "@cosmjs/proto-signing";
import { Api } from "./rest";
import { MsgMint } from "./types/depository/v1/tx";
import { MsgBurn } from "./types/depository/v1/tx";
import { MsgWithdrawBar } from "./types/depository/v1/tx";
import { MsgDepositBar } from "./types/depository/v1/tx";
const types = [
    ["/metdeal.cryptounce.depository.v1.MsgMint", MsgMint],
    ["/metdeal.cryptounce.depository.v1.MsgBurn", MsgBurn],
    ["/metdeal.cryptounce.depository.v1.MsgWithdrawBar", MsgWithdrawBar],
    ["/metdeal.cryptounce.depository.v1.MsgDepositBar", MsgDepositBar],
];
export const MissingWalletError = new Error("wallet is required");
const registry = new Registry(types);
const defaultFee = {
    amount: [],
    gas: "200000",
};
const txClient = async (wallet, { addr: addr } = { addr: "http://localhost:26657" }) => {
    if (!wallet)
        throw MissingWalletError;
    const client = await SigningStargateClient.connectWithSigner(addr, wallet, { registry });
    const { address } = (await wallet.getAccounts())[0];
    return {
        signAndBroadcast: (msgs, { fee, memo } = { fee: defaultFee, memo: "" }) => client.signAndBroadcast(address, msgs, fee, memo),
        msgMint: (data) => ({ typeUrl: "/metdeal.cryptounce.depository.v1.MsgMint", value: data }),
        msgBurn: (data) => ({ typeUrl: "/metdeal.cryptounce.depository.v1.MsgBurn", value: data }),
        msgWithdrawBar: (data) => ({ typeUrl: "/metdeal.cryptounce.depository.v1.MsgWithdrawBar", value: data }),
        msgDepositBar: (data) => ({ typeUrl: "/metdeal.cryptounce.depository.v1.MsgDepositBar", value: data }),
    };
};
const queryClient = async ({ addr: addr } = { addr: "http://localhost:1317" }) => {
    return new Api({ baseUrl: addr });
};
export { txClient, queryClient, };
