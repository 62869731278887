import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/Index.vue'
import Bars from '@/views/Bars.vue'
import Emission from '@/views/Emission.vue'
import Rewards from '@/views/Rewards.vue'

const routerHistory = createWebHistory()
const routes = [
	{ path: '/', component: Index, meta: { title: 'Wallet' } },
	{ path: '/bars', component: Bars, meta: { title: 'Bars' } },
	{ path: '/emission', component: Emission, meta: { title: 'Emission' } },
	{ path: '/delegate', component: Rewards, meta: { title: 'Delegations' } }
]

const router = createRouter({
	history: routerHistory,
	routes
})

router.afterEach((to) => {
	if (to.meta && to.meta.title) {
		document.title = 'Cryptounce.Network | ' + to.meta.title
	} else {
		document.title = 'Cryptounce.Network'
	}
})

export default router
