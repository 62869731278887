import { Reader, Writer } from 'protobufjs/minimal';
import { SigningStargateClient } from "@cosmjs/stargate";
import { Tendermint34Client } from "@cosmjs/tendermint-rpc";
import { SimulationResponse } from "@/store/generated/cosmos/cosmos-sdk/cosmos.base.abci.v1beta1/module/types/cosmos/base/abci/v1beta1/abci.js"

const Long = require('long');
const any = require('@cosmjs/stargate/build/codec/google/protobuf/any');
const transactions = require('@cosmjs/stargate/build/codec/cosmos/tx/v1beta1/tx');
const proto_signing = require("@cosmjs/proto-signing");
const amino = require("@cosmjs/amino");
const signing = require("@cosmjs/stargate/build/codec/cosmos/tx/signing/v1beta1/signing");


const baseSimulateRequest = {};
const SimulateRequest = {
    encode(message, writer = Writer.create()) {
        if (message.tx !== undefined) {
            transactions.Tx.encode(message.tx, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = Object.assign({}, baseSimulateRequest);
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                message.tx = transactions.Tx.decode(reader, reader.uint32());
                break;
                default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    }
}


class CryptounceClient extends SigningStargateClient {
    constructor(tmClient, signer, options) {
        super(tmClient, signer, options);
    }

    static async create(endpoint, signer, options = {}) {
        const tmClient = await Tendermint34Client.connect(endpoint);
        return new CryptounceClient(tmClient, signer, options);
    }

    async simulate(signerAddress, messages, fee, memo) {
        const tx = await this.buildTx(signerAddress, messages, fee, memo);
        // console.log("REGISTRY: ", registryBytes.reduce((output, elem) => (output + ('0' + elem.toString(16)).slice(-2)), ''));

        const simulateRequest = {
            tx: tx
        }
        const requestBytes = SimulateRequest.encode(simulateRequest).finish();
        const tmClient = this.forceGetTmClient();
        const res = await tmClient.abciQuery({
            height: 0,
            path: '/cosmos.tx.v1beta1.Service/Simulate',
            data: requestBytes
        })

        const simulateResponse = SimulationResponse.decode(res.value);
        return {
            calculatedGas: Math.ceil(simulateResponse.gasInfo.gasUsed * 1.3),
            log: JSON.parse(simulateResponse.result.log)
        }
    }

    async buildTx(signerAddress, messages, fee, memo) {
        const accountFromSigner = (await this.signer.getAccounts()).find((account) => account.address === signerAddress);
        const pubkey = proto_signing.encodePubkey(amino.encodeSecp256k1Pubkey(accountFromSigner.pubkey));

        const txBodyFields = {
            messages: messages,
            memo: memo
        }

        const wrappedMessages = txBodyFields.messages.map((message) => {
            const messageBytes = this.registry.encode(message);
            return any.Any.fromPartial({
                typeUrl: message.typeUrl,
                value: messageBytes,
            });
        });

        const txBody = transactions.TxBody.fromPartial(Object.assign(Object.assign({}, txBodyFields), { messages: wrappedMessages }));

        const { accountNumber, sequence } = await this.getSequence(signerAddress);

        const authInfo = {
            signerInfos: [{
                publicKey: pubkey,
                modeInfo: {
                    single: { mode: signing.SignMode.SIGN_MODE_DIRECT },
                },
                sequence: new Long(sequence),
            }],
            fee: fee,
        };

        return transactions.Tx.fromPartial({
            body: txBody,
            authInfo: authInfo,
            signatures: ['']
        })
    }
}

export { CryptounceClient }