<template>
	<div v-if="depsLoaded">
		<div class="sp-token-send__holder">
			<div class="sp-component sp-token-send">
				<div class="sp-token-send__header sp-component-title">
					<h3>REWARDS</h3>
				</div>
				<div class="sp-token-send__main sp-box sp-shadow">
					<form class="sp-token-send__main__form">
						<div class="sp-token-send__main__amt__header sp-box-header">REWARD AMOUNT</div>
						<div class="sp-token-send__main__amt__wrapper" v-if="address">
							<span>{{ rewardAmount }}</span>
						</div>

						<div class="sp-token-send__main__footer" v-if="address">
							<div class="sp-token-send__main__fees__header sp-box-header">FEE:</div>
							<div class="sp-token-send__main__fees__content">
								<div class="sp-token-send__main__fees__small">
									<span><strong> 0.04</strong> CRG </span>
								</div>
							</div>

							<div class="sp-token-send__main__btns">
								<div class="sp-token-send__main__btns__tx">
									<SpButton v-on:click="claimReward" type="primary" :disabled="!canReward" :busy="inFlight">Claim</SpButton>
								</div>
							</div>
						</div>

						<div class="sp-token-send__main__footer" v-else>
							<div class="sp-token-send__main__fees__header sp-box-message">Access a wallet to access reward</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { SpButton } from '@starport/vue'
import Coin from '@/utils/coin.js'

export default defineComponent({
	name: 'Reward',
	components: {
		SpButton
	},
	props: {
		address: String
	},
	data: function () {
		return {
			inFlight: false
		}
	},
	beforeCreate: function () {
		const vuexModule = ['cosmos.distribution.v1beta1']
		for (let i = 1; i <= vuexModule.length; i++) {
			const submod = vuexModule.slice(0, i)
			if (!this.$store.hasModule(submod)) {
				console.log('Module `cosmos.cosmos-sdk.distribution` has not been registered!')
				this._depsLoaded = false
				break
			}
		}
	},
	mounted: async function () {
		if (this._depsLoaded) {
			if (this.address != '') {
				await this.$store.dispatch('cosmos.distribution.v1beta1/QueryDelegationTotalRewards', {
					params: { delegator_address: this.address },
					options: {}
				})
			}
		}
	},
	watch: {
		address: function (newAddr, oldAddr) {
			if (this._depsLoaded) {
				if (newAddr != oldAddr) {
					if (this.address != '') {
						this.$store.dispatch('cosmos.distribution.v1beta1/QueryDelegationTotalRewards', {
							params: { delegator_address: this.address },
							options: {}
						})
					}
				}
			}
		}
	},
	computed: {
		depsLoaded: function () {
			return this._depsLoaded
		},
		rewardAmount: function () {
			let rewards =
				this.$store.getters['cosmos.distribution.v1beta1/getDelegationTotalRewards']({
					params: { delegator_address: this.address }
				})?.total ?? []
			if (rewards.length > 0) {
				let metadatas = this.$store.getters['cosmos.bank.v1beta1/getDenomsMetadata']({ params: {} }).metadatas
				let metadata = metadatas.find((m) => m.base == rewards[0].denom)
				let reward = new Coin(metadata).toDisplay(rewards[0].amount, rewards[0].denom)
				return `${reward.amount} ${reward.denom.toUpperCase()}`
			}
			return '0 CRG'
		},
		canReward: function () {
			let rewards =
				this.$store.getters['cosmos.distribution.v1beta1/getDelegationTotalRewards']({
					params: { delegator_address: this.address }
				})?.total ?? []
			return rewards.length > 0 && rewards[0].amount > 0
		}
	},
	methods: {
		claimReward: async function () {
			this.inFlight = true
			let rewards =
				this.$store.getters['cosmos.distribution.v1beta1/getDelegationTotalRewards']({
					params: { delegator_address: this.address }
				})?.rewards ?? []

			for (let i in rewards) {
				let reward = rewards[i]
				let value = {
					validatorAddress: reward.validator_address,
					delegatorAddress: this.address
				}

				console.log(value)

				try {
					const txResult = await this.$store.dispatch('cosmos.distribution.v1beta1/sendMsgWithdrawDelegatorReward', {
						value,
						fee: [{ denom: 'ucrg', amount: '40000' }],
						memo: ''
					})
				} catch (e) {
					console.error(e)
				}
				await this.$store.dispatch('cosmos.distribution.v1beta1/QueryDelegationTotalRewards', {
					params: { delegator_address: this.address },
					options: {}
				})
			}
			this.inFlight = false
		}
	}
})
</script>
