/* eslint-disable */
import { Reader, Writer } from 'protobufjs/minimal';
import { Coin } from '../../cosmos/base/v1beta1/coin';
export const protobufPackage = 'metdeal.cryptounce.depository.v1';
const baseMsgMint = { minter: '' };
export const MsgMint = {
    encode(message, writer = Writer.create()) {
        if (message.minter !== '') {
            writer.uint32(10).string(message.minter);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgMint };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.minter = reader.string();
                    break;
                case 2:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseMsgMint };
        if (object.minter !== undefined && object.minter !== null) {
            message.minter = String(object.minter);
        }
        else {
            message.minter = '';
        }
        if (object.amount !== undefined && object.amount !== null) {
            message.amount = Coin.fromJSON(object.amount);
        }
        else {
            message.amount = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        message.minter !== undefined && (obj.minter = message.minter);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    fromPartial(object) {
        const message = { ...baseMsgMint };
        if (object.minter !== undefined && object.minter !== null) {
            message.minter = object.minter;
        }
        else {
            message.minter = '';
        }
        if (object.amount !== undefined && object.amount !== null) {
            message.amount = Coin.fromPartial(object.amount);
        }
        else {
            message.amount = undefined;
        }
        return message;
    }
};
const baseMsgMintResponse = {};
export const MsgMintResponse = {
    encode(_, writer = Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgMintResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        const message = { ...baseMsgMintResponse };
        return message;
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    fromPartial(_) {
        const message = { ...baseMsgMintResponse };
        return message;
    }
};
const baseMsgBurn = { burner: '' };
export const MsgBurn = {
    encode(message, writer = Writer.create()) {
        if (message.burner !== '') {
            writer.uint32(10).string(message.burner);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgBurn };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.burner = reader.string();
                    break;
                case 2:
                    message.amount = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseMsgBurn };
        if (object.burner !== undefined && object.burner !== null) {
            message.burner = String(object.burner);
        }
        else {
            message.burner = '';
        }
        if (object.amount !== undefined && object.amount !== null) {
            message.amount = Coin.fromJSON(object.amount);
        }
        else {
            message.amount = undefined;
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        message.burner !== undefined && (obj.burner = message.burner);
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        return obj;
    },
    fromPartial(object) {
        const message = { ...baseMsgBurn };
        if (object.burner !== undefined && object.burner !== null) {
            message.burner = object.burner;
        }
        else {
            message.burner = '';
        }
        if (object.amount !== undefined && object.amount !== null) {
            message.amount = Coin.fromPartial(object.amount);
        }
        else {
            message.amount = undefined;
        }
        return message;
    }
};
const baseMsgBurnResponse = {};
export const MsgBurnResponse = {
    encode(_, writer = Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgBurnResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        const message = { ...baseMsgBurnResponse };
        return message;
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    fromPartial(_) {
        const message = { ...baseMsgBurnResponse };
        return message;
    }
};
const baseMsgDepositBar = { operator: '', number: '', brand: '', pieces: 0, weight: '', fineWeight: '', denom: '', uri: '', uriHash: '' };
export const MsgDepositBar = {
    encode(message, writer = Writer.create()) {
        if (message.operator !== '') {
            writer.uint32(10).string(message.operator);
        }
        if (message.number !== '') {
            writer.uint32(18).string(message.number);
        }
        if (message.brand !== '') {
            writer.uint32(26).string(message.brand);
        }
        if (message.pieces !== 0) {
            writer.uint32(32).uint32(message.pieces);
        }
        if (message.weight !== '') {
            writer.uint32(42).string(message.weight);
        }
        if (message.fineWeight !== '') {
            writer.uint32(50).string(message.fineWeight);
        }
        if (message.denom !== '') {
            writer.uint32(58).string(message.denom);
        }
        if (message.uri !== '') {
            writer.uint32(66).string(message.uri);
        }
        if (message.uriHash !== '') {
            writer.uint32(74).string(message.uriHash);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgDepositBar };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.operator = reader.string();
                    break;
                case 2:
                    message.number = reader.string();
                    break;
                case 3:
                    message.brand = reader.string();
                    break;
                case 4:
                    message.pieces = reader.uint32();
                    break;
                case 5:
                    message.weight = reader.string();
                    break;
                case 6:
                    message.fineWeight = reader.string();
                    break;
                case 7:
                    message.denom = reader.string();
                    break;
                case 8:
                    message.uri = reader.string();
                    break;
                case 9:
                    message.uriHash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseMsgDepositBar };
        if (object.operator !== undefined && object.operator !== null) {
            message.operator = String(object.operator);
        }
        else {
            message.operator = '';
        }
        if (object.number !== undefined && object.number !== null) {
            message.number = String(object.number);
        }
        else {
            message.number = '';
        }
        if (object.brand !== undefined && object.brand !== null) {
            message.brand = String(object.brand);
        }
        else {
            message.brand = '';
        }
        if (object.pieces !== undefined && object.pieces !== null) {
            message.pieces = Number(object.pieces);
        }
        else {
            message.pieces = 0;
        }
        if (object.weight !== undefined && object.weight !== null) {
            message.weight = String(object.weight);
        }
        else {
            message.weight = '';
        }
        if (object.fineWeight !== undefined && object.fineWeight !== null) {
            message.fineWeight = String(object.fineWeight);
        }
        else {
            message.fineWeight = '';
        }
        if (object.denom !== undefined && object.denom !== null) {
            message.denom = String(object.denom);
        }
        else {
            message.denom = '';
        }
        if (object.uri !== undefined && object.uri !== null) {
            message.uri = String(object.uri);
        }
        else {
            message.uri = '';
        }
        if (object.uriHash !== undefined && object.uriHash !== null) {
            message.uriHash = String(object.uriHash);
        }
        else {
            message.uriHash = '';
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        message.operator !== undefined && (obj.operator = message.operator);
        message.number !== undefined && (obj.number = message.number);
        message.brand !== undefined && (obj.brand = message.brand);
        message.pieces !== undefined && (obj.pieces = message.pieces);
        message.weight !== undefined && (obj.weight = message.weight);
        message.fineWeight !== undefined && (obj.fineWeight = message.fineWeight);
        message.denom !== undefined && (obj.denom = message.denom);
        message.uri !== undefined && (obj.uri = message.uri);
        message.uriHash !== undefined && (obj.uriHash = message.uriHash);
        return obj;
    },
    fromPartial(object) {
        const message = { ...baseMsgDepositBar };
        if (object.operator !== undefined && object.operator !== null) {
            message.operator = object.operator;
        }
        else {
            message.operator = '';
        }
        if (object.number !== undefined && object.number !== null) {
            message.number = object.number;
        }
        else {
            message.number = '';
        }
        if (object.brand !== undefined && object.brand !== null) {
            message.brand = object.brand;
        }
        else {
            message.brand = '';
        }
        if (object.pieces !== undefined && object.pieces !== null) {
            message.pieces = object.pieces;
        }
        else {
            message.pieces = 0;
        }
        if (object.weight !== undefined && object.weight !== null) {
            message.weight = object.weight;
        }
        else {
            message.weight = '';
        }
        if (object.fineWeight !== undefined && object.fineWeight !== null) {
            message.fineWeight = object.fineWeight;
        }
        else {
            message.fineWeight = '';
        }
        if (object.denom !== undefined && object.denom !== null) {
            message.denom = object.denom;
        }
        else {
            message.denom = '';
        }
        if (object.uri !== undefined && object.uri !== null) {
            message.uri = object.uri;
        }
        else {
            message.uri = '';
        }
        if (object.uriHash !== undefined && object.uriHash !== null) {
            message.uriHash = object.uriHash;
        }
        else {
            message.uriHash = '';
        }
        return message;
    }
};
const baseMsgDepositBarResponse = {};
export const MsgDepositBarResponse = {
    encode(_, writer = Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgDepositBarResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        const message = { ...baseMsgDepositBarResponse };
        return message;
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    fromPartial(_) {
        const message = { ...baseMsgDepositBarResponse };
        return message;
    }
};
const baseMsgWithdrawBar = { operator: '', number: '', brand: '' };
export const MsgWithdrawBar = {
    encode(message, writer = Writer.create()) {
        if (message.operator !== '') {
            writer.uint32(10).string(message.operator);
        }
        if (message.number !== '') {
            writer.uint32(18).string(message.number);
        }
        if (message.brand !== '') {
            writer.uint32(26).string(message.brand);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgWithdrawBar };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.operator = reader.string();
                    break;
                case 2:
                    message.number = reader.string();
                    break;
                case 3:
                    message.brand = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        const message = { ...baseMsgWithdrawBar };
        if (object.operator !== undefined && object.operator !== null) {
            message.operator = String(object.operator);
        }
        else {
            message.operator = '';
        }
        if (object.number !== undefined && object.number !== null) {
            message.number = String(object.number);
        }
        else {
            message.number = '';
        }
        if (object.brand !== undefined && object.brand !== null) {
            message.brand = String(object.brand);
        }
        else {
            message.brand = '';
        }
        return message;
    },
    toJSON(message) {
        const obj = {};
        message.operator !== undefined && (obj.operator = message.operator);
        message.number !== undefined && (obj.number = message.number);
        message.brand !== undefined && (obj.brand = message.brand);
        return obj;
    },
    fromPartial(object) {
        const message = { ...baseMsgWithdrawBar };
        if (object.operator !== undefined && object.operator !== null) {
            message.operator = object.operator;
        }
        else {
            message.operator = '';
        }
        if (object.number !== undefined && object.number !== null) {
            message.number = object.number;
        }
        else {
            message.number = '';
        }
        if (object.brand !== undefined && object.brand !== null) {
            message.brand = object.brand;
        }
        else {
            message.brand = '';
        }
        return message;
    }
};
const baseMsgWithdrawBarResponse = {};
export const MsgWithdrawBarResponse = {
    encode(_, writer = Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof Uint8Array ? new Reader(input) : input;
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = { ...baseMsgWithdrawBarResponse };
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_) {
        const message = { ...baseMsgWithdrawBarResponse };
        return message;
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    fromPartial(_) {
        const message = { ...baseMsgWithdrawBarResponse };
        return message;
    }
};
export class MsgClientImpl {
    constructor(rpc) {
        this.rpc = rpc;
    }
    Mint(request) {
        const data = MsgMint.encode(request).finish();
        const promise = this.rpc.request('metdeal.cryptounce.depository.v1.Msg', 'Mint', data);
        return promise.then((data) => MsgMintResponse.decode(new Reader(data)));
    }
    Burn(request) {
        const data = MsgBurn.encode(request).finish();
        const promise = this.rpc.request('metdeal.cryptounce.depository.v1.Msg', 'Burn', data);
        return promise.then((data) => MsgBurnResponse.decode(new Reader(data)));
    }
    DepositBar(request) {
        const data = MsgDepositBar.encode(request).finish();
        const promise = this.rpc.request('metdeal.cryptounce.depository.v1.Msg', 'DepositBar', data);
        return promise.then((data) => MsgDepositBarResponse.decode(new Reader(data)));
    }
    WithdrawBar(request) {
        const data = MsgWithdrawBar.encode(request).finish();
        const promise = this.rpc.request('metdeal.cryptounce.depository.v1.Msg', 'WithdrawBar', data);
        return promise.then((data) => MsgWithdrawBarResponse.decode(new Reader(data)));
    }
}
